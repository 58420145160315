// auth.effects.ts

import { Injectable } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { AuthActions } from './auth.actions';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthEffects {

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signIn),
      mergeMap(({ username, password }) =>
        this.authService.signIn(username, password).pipe(
          map((data: any) => {
            //  this.router.navigate(['/dashboard/admin']);
            localStorage.setItem("access_token", data.access_token);
            localStorage.setItem("refresh_token", data.refresh_token);

            // this.cookieService.set('access_token', data.access_token); // Stockez le token access dans un cookie
            // this.cookieService.set('refresh_token', data.refresh_token); // Stockez le refresh_token dans un cookie
            // Dans le cas d'un login réussi, nous dispathons l'action loginSuccess pour mettre à jour le Store avec le token access.
            return AuthActions.signInSuccess({ accessToken: data.access_token, refreshToken: data.refresh_token });
          }),
          catchError((error) => {
            return of(AuthActions.signInFailure({ error: error.error.message }));
          })
        )
      )
    )
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      tap(() => {
      }),
      mergeMap(() =>
        this.authService.refreshToken().pipe(
          map((data: any) => {
            localStorage.setItem("access_token", data.access_token);
            localStorage.setItem("refresh_token", data.refresh_token);

            if (this.router.url.includes('login')) {
              this.router.navigate(['/dashboard'])
            }

            // Dans le cas d'un login réussi, nous dispathons l'action loginSuccess pour mettre à jour le Store avec le token access.
            return AuthActions.refreshTokenSuccess({ accessToken: data.access_token, refreshToken: data.refresh_token, user: localStorage.getItem('user') });
          }),
          catchError((error) => {
            return of(AuthActions.signInFailure({ error: error.error.message }));
          })

        )
      )
    )
  );

  // Exemple de déconnexion automatique après le login (vous pouvez adapter cela à vos besoins).
  signInSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signInSuccess),
        tap(() => {
          // this.router.navigate(['/dashboard']); // Redirigez vers la page souhaitée après le login réussi.
        }),
        // Appel de la méthode getUserProfile après le login réussi
        switchMap(() =>
          this.authService.getUserProfile().pipe(
            map((user: any) => {
              localStorage.setItem("user", JSON.stringify(user))

              // Redirect to Admin Dashboard or User Dashboard
              if (user.scopes) {
                const scopes = JSON.stringify(user.scopes);
                // If Admin
                if (scopes.includes('root') || scopes.includes('admin')) {
                  this.router.navigate(['/admin']);
                } else {
                  this.router.navigate(['/dashboard']);
                }
              }


              // Dispatche l'action getUserProfileSuccess avec les informations de l'utilisateur récupérées.
              return AuthActions.getUserProfileSuccess({ user: user });
            }),
            catchError((error) => {
              // Vous pouvez gérer les erreurs ici, par exemple, afficher un message d'erreur ou effectuer d'autres actions en cas d'échec du chargement du profil utilisateur.
              return of(AuthActions.getUserProfileFailure({ error: error.error.message }));
            })
          )
        )
      ),
    { dispatch: true } // Cette option permet de dispatcher une nouvelle action, car nous en avons besoin ici.
  );


  // Exemple de déconnexion automatique après le login (vous pouvez adapter cela à vos besoins).
  signOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signOut),
        tap(() => {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          this.router.navigate(['/login']); // Redirigez vers la page de connexion après la déconnexion.
        })
      ),
    { dispatch: false } // Cette option empêche de dispath une nouvelle action, car nous n'en avons pas besoin ici.
  );

  // Exemple de déconnexion automatique après le login (vous pouvez adapter cela à vos besoins).
  updateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.updateUserProfile),
        switchMap(() =>
          this.authService.getUserProfile().pipe(
            map((userData: any) => {
              localStorage.setItem("user", JSON.stringify(userData))
              // Dispatche l'action getUserProfileSuccess avec les informations de l'utilisateur récupérées.
              return AuthActions.getUserProfileSuccess({ user: userData });
            }),
            catchError((error) => {
              // Vous pouvez gérer les erreurs ici, par exemple, afficher un message d'erreur ou effectuer d'autres actions en cas d'échec du chargement du profil utilisateur.
              return of(AuthActions.getUserProfileFailure({ error: error.error.message }));
            })
          )
        )
      ),
    { dispatch: false } // Cette option empêche de dispath une nouvelle action, car nous n'en avons pas besoin ici.
  );


  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => {
        const accessToken = localStorage.getItem("access_token");
        const refreshToken = localStorage.getItem("refresh_token");

        if (accessToken && refreshToken) {
          // Dispatche l'action loginSuccess si les tokens sont présents
          // return of(AuthActions.signInSuccess({ accessToken: accessToken, refreshToken: refreshToken }));
          return of(AuthActions.refreshToken());
        } else {
          // Dispatche l'action logout si les tokens ne sont pas présents
          return of(AuthActions.notLogged());
        }

      })
    ),
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router
  ) { }
}
