// auth.service.ts

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AuthState } from '../store/auth.reducer';
import { AuthActions } from '../store/auth.actions';

@Injectable()
export class AuthService {

  constructor(
    private store: Store<{ auth: AuthState }>,
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  signIn(username: string, password: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/auth/signin`,
      {
        "username": username,
        "password": password
      }
    ).pipe(
      tap((data: any) => {
      })
    );
  }

  signOut() {
    this.store.dispatch(AuthActions.signOut());
  }


  refreshToken(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/auth/refresh`);
  }

  callRefreshToken(previousRequest?: string) {
    this.store.dispatch(AuthActions.refreshToken());
  }


  getUserProfile() {
    return this.http.get(`${environment.apiUrl}/api/auth/profile`);
  }


}
