import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

@Component({
  selector: 'app-filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterCardComponent {

  // Inputs
  @Input() config: Array<any> = [];

  // Form Variables
  filterForm = new FormGroup({});
  filterModel: any = {};
  filterOptions: FormlyFormOptions = {};
  filterField: FormlyFieldConfig[] = []


  ngOnInit(): void {
  }

}



